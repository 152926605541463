@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@600&display=swap');

@font-face {
  font-family: 'GeneralSans';
  src: url('/public/general-sans/GeneralSans-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSans';
  src: url('/public/general-sans/GeneralSans-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSans';
  src: url('/public/general-sans/GeneralSans-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSans';
  src: url('/public/general-sans/GeneralSans-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'CustomPasswordFont';
  src: url('/public/customPass.woff2') format('woff2');
}
@layer components {
  .password-visible {
    -webkit-text-security: none;
    font-family: "CustomPasswordFont", sans-serif;
  }
}
body {
  margin: 0;
  font-family: 'GeneralSans',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom CSS to hide the increment and decrement buttons */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

input[type="password"] {
  font-family: 'CustomPasswordFont', sans-serif;
  font-size: 20px; /* Adjust as needed */
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  /* Prevent horizontal overflow */
}